

<template>
<!-- 登录 -->
    <div id="back">
        <el-row type="flex" class="row-bg" justify="center" align="middle">
            <el-col  :lg="8" :xl="8" :sm="12" :md="10" :xs="20" >
                <el-input type="password" v-model="pwd" placeholder="请输入登录密码" style="width:50%"></el-input>
                <el-button  style="margin-left:10px;" size="medium" @click="home" type="primary">登录</el-button>
            </el-col>
        </el-row>

    </div>
</template>

<script>
/*
* @Description:   1、login 登录
* @Author:
* @Date: 2021-05-10 20:37:44
*/
    import {login} from "@/api/login.js"
    export default {
        data(){
            return {
                pwd:''//密码
            }
        },
        methods:{
            /*
            * @Description:登录功能
            * @Author:
            * @Date: 2021-05-10 20:36:27
            */
            home(){
                login({password:this.pwd}).then(res=>{
                  if(res.data.code==1){
                    this.$message.error("密码错误");
                  }else{
                    // console.log(res.data.token)
                    sessionStorage.setItem("token",res.data.data.token)
                    this.$router.push({path:"/backstage",name:"后台主页"})
                  }

                  console.log(res,'成功')
                }).catch(err=>{
                  console.log(err,'失败')
                })

            }
        }
    }
</script>

<style lang="scss" scoped>
#back{
    background: url(/img/three.jpg);
    background-size: 100% ;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
}
 .el-row {
        height: 100%; 
    }
</style>