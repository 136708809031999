import request  from "axios";
// import {url} from "@/const/url.js"
//新增编辑广告内容
export  function editAdd(obj){
    return request({
        url:"/api/user/editAdd",
        method:"POST",
        data:obj
    })
}
//修改登录密码
export  function changePassword(obj){
    return request({
        url:"/api/user/changePassword",
        method:"POST",
        data:obj
    })
}
//新增账号
export  function  addUser(obj){
    return request({
        url:"/api/user/addUser",
        method:"POST",
        data:obj
    })
}
//修改账号
export  function  updateUser(obj){
    return request({
        url:"/api/user/updateUser",
        method:"POST",
        data:obj
    })
}
//删除账号
export  function  delUser(obj){
    return request({
        url:"/api/user/delUser",
        method:"POST",
        data:obj
    })
}