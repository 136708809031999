<template>
<!-- 主页 -->
    <div >
<!--        <el-button size="mini" @click="login">登录</el-button>-->
<!--      <el-col :span="24" style="padding: 2px 20px">-->
<!--        <el-button type="text" @click="($router.push({path:'/login'}))">登录</el-button>-->
<!--      </el-col>-->
<!--      <el-time-picker-->
<!--          is-range-->
<!--          v-model="value1"-->
<!--          :default-value="value2"-->
<!--          value-format="HH:mm:ss"-->
<!--          range-separator="至"-->
<!--          start-placeholder="开始时间"-->
<!--          end-placeholder="结束时间"-->
<!--          placeholder="选择时间范围">-->
<!--      </el-time-picker>-->
<!--      <el-time-picker is-range-->
<!--                      v-model="form1.time"-->
<!--                      format="HH:mm:ss"-->
<!--                      value-format="HH:mm:ss"-->
<!--                      range-separator="-"-->
<!--                      :default-value="beginTimes"-->
<!--                      start-placeholder="开始时间"-->
<!--                      end-placeholder="结束时间"-->
<!--                      placeholder="请选择用车时间范围">-->
<!--      </el-time-picker>-->
      <el-row style="padding: 10px 20px" type="flex" class="row-bg" justify="center">

        <el-col :span="12" >
          <el-input placeholder="请输入账号名字查询" v-model="searchData"></el-input>
        </el-col>
        <el-col><el-button style="margin-left: 20px" type="primary" @click="search">查询</el-button></el-col>
      </el-row>
      <el-row style="padding: 10px 20px">
        <el-col :span="24">
          <el-table :data="tableData" border style="width: 100%" :header-cell-style="{background:'#eee'}">
            <el-table-column prop="username" label="账号名称" >
            </el-table-column>
            <el-table-column  label="操作" width="180">
              <template slot-scope="scope">
                <el-button type="text" @click="see(scope.row)">
                  查看详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24" style="padding-top: 10px ;text-align: right" >
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </el-col>
      </el-row>
      <el-row style="padding: 10px 20px">
        <el-col :span="24" v-html="cont">

        </el-col>
      </el-row>

      <el-dialog
          title="用户详情"
          :visible.sync="centerDialogVisible"
          width="50%"
          center>
        <el-form label-width="100px">
          <el-form-item label="用户名称:">
            <span>{{form.username}}</span>
          </el-form-item>
          <el-form-item label="账号提示:">
            <span>{{form.userhint}}</span>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
</template>

<script>
/*
* @Description: 1、getAccountList  获取账号列表 2、getAccountDetail 查看用户详情  2、getAdd 获取广告
* @Author:
* @Date: 2021-05-10 20:36:43
*/

import {getAccountList,getAccountDetail,getAdd} from "@/api/home.js"

    export default {
        data(){
          return {
          //   beginTimes:[new Date(2021,6,10,8,0),
          //     new Date(2021,6,10,23,59)],
          //   form1:{
          //     time:''
          //   },
          // value1: '',
          //   value2: [new Date(2016, 9, 10, 8,0),
          //     new Date(2016, 9, 10, 23, 59)],
          //   defaultTiming:'',
            tableData:[//表格绑定数据

            ],
            page:1,//当前页
            size:10,//每页多少条
            searchData:"",//搜索内容
            total:10,//总页数
            centerDialogVisible:false,//对话框显隐
            form:'',//账号详情
            cont:"",//广告内容
          }
        },
        created() {

          //获取表格数据
          this.getAccountList();
          //获取广告
          this.getAdd();
        },
      methods:{
        /**
         * 将时间格式化为标准字符串==HH-MM-DD HH:MI:ss
         *
         *
         * */
        createTimeStr (time = new Date(), type = 'ymdhis') {
          let date = new Date(time)
          let Str = ''
          let year = date.getFullYear()
          let month = date.getMonth() + 1
          if (month < 10)month = '0' + month
          let day = date.getDate()
          if (day < 10) day = '0' + day
          let hours = date.getHours()
          if (hours < 10)hours = '0' + hours
          let minutes = date.getMinutes()
          if (minutes < 10)minutes = '0' + minutes
          let Seconds = date.getSeconds()
          if (Seconds < 10)Seconds = '0' + Seconds

          if (type === 'ymdhis') {
            Str = year + '-' +
                month + '-' +
                day + ' ' +
                hours + ':' +
                minutes + ':' +
                Seconds
          } else if (type === 'ymd') {
            Str = year + '-' +
                month + '-' +
                day
          } else if (type === 'his') {
            Str = hours + ':' +
                minutes + ':' +
                Seconds
          }
          return Str
        },
          /*
          * @Description:搜索
          * @Author:
          * @Date: 2021-05-12 10:35:56
          */
            search(){
              this.page=1;
              //获取表格数据
              this.getAccountList();
            },
            /*
            * @Description:  获取用户数据
            * @Author:
            * @Date: 2021-05-10 21:05:16
            */
            getAccountList(){
              const obj={
                page:this.page,//当前页
                size:this.size,//每页多少条
                searchData:this.searchData,//搜索内容
              }
              getAccountList(obj).then(res=>{
                this.tableData=res.data.data.records;//赋值表格数据
                // console.log(res,'用户数据')
                this.total=res.data.data.total;
              }).catch(err=>{
                this.$message.error(err);
              })
            },
            /*
            * @Description: 查看详情
            * @Author:
            * @Date: 2021-05-10 21:03:21
            */
            see(e){
              //获取用户数据
              getAccountDetail({uid:e.uid}).then(res=>{
                this.form=res.data.data;
                this.centerDialogVisible=true;
                // console.log(res,'查询用户数据')

              }).catch(err=>{
                this.$message.error(err);
              })
            },
            /*
            * @Description:  获取广告信息
            * @Author:
            * @Date: 2021-05-10 21:07:03
            */
            getAdd(){
              getAdd({}).then(res=>{
                this.cont=res.data.data.content;//赋值广告信息
                // console.log(res,'广告信息')
              }).catch(err=>{
                this.$message.error(err);
              })
            },
            /*
            * @Description: 每页显示条数变化
            * @Author:
            * @Date: 2021-05-10 21:11:48
            */
            handleSizeChange(e){
              this.size=e//每页多少条
              //获取表格数据
              this.getAccountList();
            },
            /*
            * @Description: 当前页变化
            * @Author:
            * @Date: 2021-05-10 21:12:03
            */
            handleCurrentChange(e){
              this.page=e;
              //获取表格数据
              this.getAccountList();
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>