<template>
  <!-- 主页 -->
  <div >
    <!--        <el-button size="mini" @click="login">登录</el-button>-->
    <el-row style="padding: 10px 20px" type="flex" class="row-bg">
      <el-col :span="8" >
        <el-input v-model="searchData" placeholder="请输入账号名字查询" ></el-input>
      </el-col>
      <el-col :span="2"><el-button style="margin-left: 20px" type="primary" @click="search">查询</el-button></el-col>
      <el-col :span="3"><el-button style="margin-left: 20px" type="success" @click="added">新增账号</el-button></el-col>
      <el-col :span="3"><el-button style="margin-left: 20px" type="success" @click="addAdvertisement">新增/编辑广告</el-button></el-col>
      <el-col :span="3"><el-button style="margin-left: 20px" type="danger" @click="updatePwd">修改登录密码</el-button></el-col>
    </el-row>
    <el-row style="padding: 10px 20px">
      <el-col :span="24">
        <el-table v-loading="loading" :data="tableData" border style="width: 100%" :header-cell-style="{background:'#eee'}">
          <el-table-column prop="username" label="账号名称" >
          </el-table-column>
          <el-table-column  label="操作" width="180">
            <template slot-scope="scope">
              <el-button type="text" @click="addUser(scope.row)">
                编辑
              </el-button>
              <el-button @click="del(scope.row)" type="text" >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="24" style="padding-top: 10px ;text-align: right" >
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </el-col>
    </el-row>
    <el-dialog
        :title="title"
        :visible.sync="centerDialogVisible"
        width="50%"
        center
        :before-close="handleDialogClose"
    >
<!--      编辑账号信息-->
      <el-row v-if="flag==1||flag==2">
        <el-col>
          <el-form label-width="100px" ref="form" :rules="rules" :model="form">
            <el-form-item label="用户名称:" prop="username">
              <el-input style="width: 100%" placeholder="请输入用户名" v-model.trim="form.username"></el-input>
            </el-form-item>
            <el-form-item label="账号提示:" prop="userhint">
              <el-input type="textarea" :autosize="{max:10,min:3}" style="width: 100%" maxlength="600" v-model.trim="form.userhint" placeholder="请输入账号提示"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-col style="text-align: right">
                <el-button type="primary" @click="updateUser('form')">确定</el-button>
                <el-button type="info" @click="handleDialogClose">取消</el-button>
              </el-col>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <!--  修改登录密码-->
      <el-form label-width="100px" v-if="flag==3" ref="password" :rules="rules" :model="password">
        <el-form-item label="新的密码:" prop="password">
          <el-input type="password" v-model.trim="password.password" style="width: 100%" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-col style="text-align: right">
            <el-button type="primary"  @click="preservation('password')">确定</el-button>
            <el-button type="info" @click="handleDialogClose">取消</el-button>
          </el-col>
        </el-form-item>
      </el-form>
      <!--  编辑广告-->
      <el-row v-if="flag==4">
        <el-col>
          <el-input type="textarea" rows="15" v-model="content" >

          </el-input>
        </el-col>
        <el-col style="text-align: right;padding:10px 0">
          <el-button type="primary" @click="editAdd">确定</el-button>
          <el-button type="info" @click="handleDialogClose">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
/*
* @Description: 1、getAccountList  获取账号列表 2、getAccountDetail 查看用户详情 3、getAdd 获取广告
* @Author:
* @Date: 2021-05-10 20:36:43
*/
import {getAccountList,getAccountDetail,getAdd} from "@/api/home.js"

/*
* @Description :1、editAdd  新增编辑广告内容  2、changePassword  修改登录密码 3、addUser 新增账号 4、updateUser 修改账号  5、删除账号
* @Author:
* @Date: 2021-05-10 21:40:56
*/
import {editAdd,changePassword,addUser,delUser,updateUser} from "@/api/backstage.js"
export default {
  data(){
    return {
      loading:false,//加载中
      tableData:[//表格绑定数据
        {
          username:"2222222222",//用户名
          userhint:"22222222222222",//用户提示
          uid:1//用户id
        }
      ],
      page:1,//当前页
      size:10,//每页多少条
      searchData:"",//搜索内容
      total:10,//总页数
      centerDialogVisible:false,//对话框显隐
      form:{
        username:'',//用户名
        userhint:''//用户介绍
      },//账号详情
      flag:4,//识别对话框显示内容  1 新增账号 2 编辑账号 3修改登录密码 4 新增广告
      title:'',//对话框标题
      content:"",//广告内容
      rules:{//校验器
        username:[
          {required: true, message: '名称不能为空', trigger: 'blur' },
          {max: 16, message: '名称最长为16位', trigger: 'blur' },
          {min: 2, message: '名称最短为2位', trigger: 'blur' },
        ],
        userhint:[
          {required: true, message: '账号介绍不能为空', trigger: 'blur' },
          {max: 600, message: '账号介绍最长为600位', trigger: 'blur' },
        ],
        password:[
          {required: true, message: '密码不能为空', trigger: 'blur' },
          {max: 18, message: '密码最长为18位', trigger: 'blur' }
        ]
      },
      password:{
        password:''
      },//新密码
      token:""
    }
  },
  created(){
    if(!sessionStorage.getItem('token')){
      this.$router.push({path:'/login',name:'登录页'})
    }
    this.getAccountList();//获取用户列表数据
  },
  methods:{
    /*
    * @Description:  删除当前用户
    * @Author:
    * @Date: 2021-05-10 22:35:57
    */
    del(e){
      this.$confirm(`是否确定删除账号${e.username}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delUser({uid:e.uid}).then(res=>{
          this.$message.success('删除成功');
          this.getAccountList();//获取表格数据
        }).catch(err=>{
          this.$message.error(err)
        })
      }).catch(() => {

      });
    },
    /*
    * @Description:  修改密码对话框弹出
    * @Author:
    * @Date: 2021-05-10 22:22:14
    */
    updatePwd(){
      this.flag=3;//为3时为显示修改密码
      this.password= {
        password: ''
      };//清空密码
      this.centerDialogVisible=true;//弹出对话框
      this.title='修改密码';//当前对话框标题
      if(this.$refs.form) this.$refs.form.resetFields();//清除校验
    },
    /*
    * @Description: 确定修改密码
    * @Author:
    * @Date: 2021-05-10 22:32:40
    */
    preservation(fname,err){
      this.$refs[fname].validate((valid)=>{
        if(valid){
          changePassword({password:this.password.password}).then(res=>{
            this.$message.success('修改成功')
            this.centerDialogVisible=false;//关闭对话框
            // console.log(res,'修改密码成功')
          }).catch(err=>{
            this.$message.error(err)
          })
        }
      })

    },
    /*
    * @Description:  新增广告
    * @Author:
    * @Date: 2021-05-10 22:18:17
    */
    addAdvertisement(){
      this.flag=4;//等于4时为新增广告
      this.centerDialogVisible=true;//弹出对话框
      this.title='新增/编辑广告';//当前对话框标题
      if(this.$refs.form) this.$refs.form.resetFields();//清除校验
      getAdd({}).then(res=>{
        this.content=res.data.data.content;//赋值广告信息
        // console.log(res,'广告信息')
      }).catch(err=>{
        this.$message.error(err);
      })

    },
    /*
    * @Description:  新增账号
    * @Author:
    * @Date: 2021-05-10 22:13:53
    */
    added(){
      this.flag=1;//为1时为新增账号
      this.centerDialogVisible=true;//弹出对话框
      this.title='新增账号';//当前对话框标题
      this.form={
        username:"",//用户名
        userhint:""//用户提示
      }
      if(this.$refs.form) this.$refs.form.resetFields();//清除校验
    },
    /*
    * @Description: 获取当前用户详细信息
    * @Author:
    * @Date: 2021-05-10 21:03:21
    */
    addUser(e){
      this.centerDialogVisible=true;
      this.flag=2;//为2时为编辑账号
      this.centerDialogVisible=true;//弹出对话框
      this.title='编辑账号';//当前对话框标题
      //查看用户详情
      getAccountDetail({uid:e.uid}).then(res=>{
        this.form=res.data.data;
        // console.log(res,'当前用户数据')
      }).catch(err=>{
        this.$message.error(err);
      })
    },
    /*
    * @Description:  新增/编辑用户信息
    * @Author:
    * @Date: 2021-05-10 22:25:29
    */
    updateUser(fname,err){
      this.$refs[fname].validate((valid)=>{
        if(valid){
          if(this.flag==1){//新增账号
            addUser(this.form).then(res=>{
              this.getAccountList();//获取表格数据
              this.centerDialogVisible=false;//关闭对话框
              this.$message.success('添加成功')
              // console.log(res,'添加成功')
            }).catch(err=>{
              this.$message.error(err)
              // console.log(err)
            })
          }
          if(this.flag==2){//编辑账号
            updateUser(this.form).then(res=>{
              this.getAccountList();//获取表格数据
              this.centerDialogVisible=false;//关闭对话框
              this.$message.success('修改成功')
              // console.log(res,'修改成功')
            }).catch(err=>{
              this.$message.error(err)
              // console.log(err)
            })
          }
        }else{
          // console.log('校验不通过')
        }
      })
    },
    /*
    * @Description:  通过用户名查询数据
    * @Author:
    * @Date: 2021-05-10 22:11:46
    */
    search(){
      this.page=1;//更改当前页为1
      this.getAccountList();//获取表格数据
    },
    /*
    * @Description:  获取用户数据
    * @Author:
    * @Date: 2021-05-10 21:05:16
    */
    getAccountList(){
      //this.loading=true;
      const obj={
        page:this.page,//当前页
        size:this.size,//每页多少条
        searchData:this.searchData,//搜索内容
      }
      getAccountList(obj).then(res=>{
        // console.log(res,'用户数据')
        this.tableData=res.data.data.records;
        this.total=res.data.data.total;
        this.loading=false;
      }).catch(err=>{
        this.$message.error(err)
       this.loading=false;
      })
    },
    /*
    * @Description:  新增广告信息
    * @Author:
    * @Date: 2021-05-10 21:07:03
    */
    editAdd(){
      editAdd({content:this.content}).then(res=>{
        this.$message.success('操作成功');
        this.centerDialogVisible=false;//关闭对话框
        // console.log(res,'广告信息')
      }).catch(err=>{
        this.$message.error(err)
      })
    },
    /*
    * @Description: 每页显示条数变化
    * @Author:
    * @Date: 2021-05-10 21:11:48
    */
    handleSizeChange(e){
      this.size=e;
      this.getAccountList();//获取表格数据
    },
    /*
    * @Description: 当前页变化
    * @Author:
    * @Date: 2021-05-10 21:12:03
    */
    handleCurrentChange(e){
      this.page=e;
      this.getAccountList();//获取表格数据
    },
    /*
    * @Description:  关闭抽屉
    * @Author:
    * @Date: 2021-05-10 22:03:42
    */
    handleDialogClose(done){
      this.$confirm('是否取消编辑?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.centerDialogVisible=false;
        if(this.$refs.form) this.$refs.form.resetFields();//清除校验
        done()
      }).catch(() => {

      });

    }
  }
}
</script>

<style lang="scss" scoped>

</style>