import request  from "axios";
// import {url} from "@/const/url.js"
// 获取账号列表（分页）
export function getAccountList(obj){
    return request({
            url:"/api/index/getAccountList",
            method:"POST",
            data:obj
        })
} 
// 根据id查看账号详情
export function getAccountDetail(obj){
    return request({
            url:"/api/index/getAccountDetail",
            method:"POST",
            data:obj
        })
}
// 获取广告内容
export function getAdd(obj){
    return request({
            url:"/api/index/getAdd",
            method:"POST",
            data:obj
        })
}
